import { ApexChart, NgApexchartsModule } from 'ng-apexcharts';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
// Header Elements Components
import { SearchComponent } from './components/header/elements/search/search.component';
import { MegaMenuComponent } from './components/header/elements/mega-menu/mega-menu.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { BookmarkComponent } from './components/header/elements/bookmark/bookmark.component';
import { CartComponent } from './components/header/elements/cart/cart.component';
import { MessageBoxComponent } from './components/header/elements/message-box/message-box.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
// Services
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContextComponent } from './components/context/context.component';
import { SelectFirstInputDirective } from './directives/select-first-input.directive';
import { SelectInputContentOnFocusDirective } from './directives/select-input-content-on-focus.directive';
import { AddNewClientComponent } from './components/modals/add-new-client/add-new-client.component';
import { AddNewArticleComponent } from './components/modals/add-new-article/add-new-article.component';
import { SaveInvoiceDraftComponent } from './components/modals/save-invoice-draft/save-invoice-draft.component';
import { NgxMaskModule } from 'ngx-mask';
import { SettingsComponent } from './components/header/elements/settings/settings.component';
import { FiscalizationModalComponent } from './components/modals/Fiscalization/fiscalization-modal/fiscalization-modal.component';
import { ReportsComponent } from './components/reports/reports.component';
import { FilterModalComponent } from './components/modals/filter-modal/filter-modal.component';
import { InvoiceHistoryModalComponent } from './components/modals/invoice-history-modal/invoice-history-modal.component';
import { UserAdministrationFormModalComponent } from './components/modals/user-administration-form-modal/user-administration-form-modal.component';
import { SearchFilterPipe } from './services/searchByName.pipe';
import { OwlDateTimeModule, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { EditorModule } from '@tinymce/tinymce-angular';

import { NumberInputComponent } from './components/number-input/number-input.component';
import { RolesAdministrationFormModalComponent } from './components/modals/roles-administration-form-modal/roles-administration-form-modal.component';
import { TextAreaEditorComponent } from './components/text-area-editor/text-area-editor.component';
import { InlineEditorComponent } from './components/inline-editor/inline-editor.component';
import { NumberInputKolicinaComponent } from './components/number-input-kolicina/number-input-kolicina.component';
import { FilterModalFfObilasciComponent } from './components/modals/filter-modal-ff-obilasci/filter-modal-ff-obilasci.component';
import { FilterModalFfObilasciKomitentiSumarnoComponent } from './components/modals/filter-modal-ff-obilasci-komitenti-sumarno/filter-modal-ff-obilasci-komitenti-sumarno.component';
import { FilterModalFfNeobidjeniKomitentiComponent } from './components/modals/filter-modal-ff-neobidjeni-komitenti/filter-modal-ff-neobidjeni-komitenti.component';
import { FilterModalOdDoComponent } from './components/modals/filter-modal-od-do/filter-modal-od-do.component';
import { IosModalComponent } from './components/modals/ios-modal/ios-modal.component';
import { PoValutiModalComponent } from './components/modals/po-valuti-modal/po-valuti-modal.component';
import { FilterPipe } from './services/filter.pipe';
import { PoValutiAdvancedModalComponent } from './components/modals/po-valuti-advanced-modal/po-valuti-advanced-modal.component';
import { DateAsAgoPipe } from './pipes/date-as-ago.pipe';
import { PostavkeListModalComponent } from './components/modals/postavke/postavke-list-modal/postavke-list-modal.component';
import { YesnoModalComponent } from './components/modals/yesno-modal/yesno-modal.component';
import { AddNewCityComponent } from './components/modals/add-new-city/add-new-city.component';
import { FilterModalPoOperaterimaComponent } from './components/modals/filter-modal-po-operaterima/filter-modal-po-operaterima.component';
import { ReportPoOperaterimaComponent } from './components/reports/report-po-operaterima/report-po-operaterima.component';
import { BarcodeScannerModalComponent } from './components/modals/barcode-scanner-modal/barcode-scanner-modal.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { UpdateDostavaModalComponent } from './components/modals/update-dostava-modal/update-dostava-modal.component';
import { ErrorListModalComponent } from './components/modals/error-list-modal/error-list-modal.component';
import { ModalMjesecComponent } from './components/modals/modal-mjesec/modal-mjesec.component';
import { WaitingModalComponent } from './components/modals/waiting-modal/waiting-modal.component';
import { ModalEIsporukeNabavkeModalComponent } from './components/modals/modal-e-isporuke-nabavke-modal/modal-e-isporuke-nabavke-modal.component';
import { GreskeKnjizenjaListModalComponent } from './components/modals/greske-knjizenja-list-modal/greske-knjizenja-list-modal.component';
import { AddNewKontoComponent } from './components/modals/add-new-konto/add-new-konto.component';
import { AddNewNalogComponent } from './components/modals/add-new-nalog/add-new-nalog.component';
import { AddNewFinDokumentComponent } from './components/modals/add-new-fin-dokument/add-new-fin-dokument.component';
import { TakePictureModalComponent } from './components/modals/take-picture-modal/take-picture-modal.component';
import { WebcamModule } from 'ngx-webcam';
import { TourNgxBootstrapModule } from 'ngx-ui-tour-ngx-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPaginationComponent } from './components/ngx-pagination/ngx-pagination.component';
import { AddNewFfPoljeComponent } from './components/modals/add-new-ff-polje/add-new-ff-polje.component';
import { AddNewFfTaskComponent } from './components/modals/add-new-ff-task/add-new-ff-task.component';
import { NumberInputFinancijeComponent } from './components/number-input-financije/number-input-financije.component';
import { UserDropdownComponent } from './components/header/elements/user-dropdown/user-dropdown/user-dropdown.component';
import { AddNewDmsComponent } from './components/modals/add-new-dms/add-new-dms.component';
import { KomitentiZiroRacuniModalComponent } from './components/modals/komitenti-ziro-racuni-modal/komitenti-ziro-racuni-modal.component';
import { DmsKomentarComponent } from './components/dms-komentar/dms-komentar.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { NgxMentionModule } from '@netcreaties/ngx-mention';
import { FilterModalNumberComponent } from './components/modals/filter-modal-number/filter-modal-number.component';
import { FilterOdDoDatumComponent } from './components/modals/filter-od-do-datum/filter-od-do-datum.component';
import { FilterNaDanDatumComponent } from './components/modals/filter-na-dan-datum/filter-na-dan-datum.component';
import { ClipboardListModalComponent } from './components/modals/clipboard-list-modal/clipboard-list-modal.component';
import { AddNewFinProjektComponent } from './components/modals/add-new-fin-projekt/add-new-fin-projekt.component';
import { OdaberiFinNalogModalComponent } from './components/modals/odaberi-fin-nalog-modal/odaberi-fin-nalog-modal.component';
import { OdaberiTipPocetnogStanjaModalComponent } from './components/modals/odaberi-tip-pocetnog-stanja-modal/odaberi-tip-pocetnog-stanja-modal.component';
import { FilterOdDoDatumProjektComponent } from './components/modals/filter-od-do-datum-projekt/filter-od-do-datum-projekt.component';
import { ModalMjesecGodinaComponent } from './components/modals/modal-mjesec-godina/modal-mjesec-godina.component';
import { AddNewObracunPlacaComponent } from './components/modals/add-new-obracun-placa/add-new-obracun-placa.component';
import { ObracuniPlacaStatusModalComponent } from './components/modals/obracuni-placa-status-modal/obracuni-placa-status-modal.component';
import { ModalGodinaComponent } from './components/modals/modal-godina/modal-godina.component';
import { ModalRadnikSelectComponent } from './components/modals/modal-radnik-select/modal-radnik-select.component';
import { PdvKnjizenjeObracunaPdvaModalComponent } from './components/modals/pdv-knjizenje-obracuna-pdva-modal/pdv-knjizenje-obracuna-pdva-modal.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    TooltipComponent,
    ConfirmationModalComponent,
    ContextComponent,
    SelectFirstInputDirective,
    SelectInputContentOnFocusDirective,
    AddNewClientComponent,
    AddNewArticleComponent,
    SaveInvoiceDraftComponent,
    SettingsComponent,
    FiscalizationModalComponent,
    ReportsComponent,
    FilterModalComponent,
    InvoiceHistoryModalComponent,
    UserAdministrationFormModalComponent,
    RolesAdministrationFormModalComponent,
    SearchFilterPipe,
    NumberInputComponent,
    TextAreaEditorComponent,
    InlineEditorComponent,
    NumberInputKolicinaComponent,
    FilterModalFfObilasciComponent,
    FilterModalFfObilasciKomitentiSumarnoComponent,
    FilterModalFfNeobidjeniKomitentiComponent,
    FilterModalOdDoComponent,
    IosModalComponent,
    PoValutiModalComponent,
    FilterPipe,
    PoValutiAdvancedModalComponent,
    DateAsAgoPipe,
    PostavkeListModalComponent,
    YesnoModalComponent,
    AddNewCityComponent,
    FilterModalPoOperaterimaComponent,
    ReportPoOperaterimaComponent,
    BarcodeScannerModalComponent,
    UpdateDostavaModalComponent,
    ErrorListModalComponent,
    ModalMjesecComponent,
    WaitingModalComponent,
    ModalEIsporukeNabavkeModalComponent,
    GreskeKnjizenjaListModalComponent,
    AddNewKontoComponent,
    AddNewNalogComponent,
    AddNewFinDokumentComponent,
    TakePictureModalComponent,
    NgxPaginationComponent,
    AddNewFfPoljeComponent,
    AddNewFfTaskComponent,
    NumberInputFinancijeComponent,
    UserDropdownComponent,
    AddNewDmsComponent,
    KomitentiZiroRacuniModalComponent,
    DmsKomentarComponent,
    FilterModalNumberComponent,
    FilterOdDoDatumComponent,
    FilterNaDanDatumComponent,
    ClipboardListModalComponent,
    AddNewFinProjektComponent,
    OdaberiFinNalogModalComponent,
    OdaberiTipPocetnogStanjaModalComponent,
    FilterOdDoDatumProjektComponent,
    ModalMjesecGodinaComponent,
    AddNewObracunPlacaComponent,
    ObracuniPlacaStatusModalComponent,
    ModalGodinaComponent,
    ModalRadnikSelectComponent,
    PdvKnjizenjeObracunaPdvaModalComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgApexchartsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    EditorModule,
    ZXingScannerModule,
    WebcamModule,
    TourNgxBootstrapModule,
    PaginationModule.forRoot(),
    PickerModule,
    EmojiModule,
    NgxMentionModule

  ],
  providers: [
    NavService,
    LayoutService,
    DatePipe,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'ba' },
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    TooltipComponent,
    SelectFirstInputDirective,
    SelectInputContentOnFocusDirective,
    SearchComponent,
    ReportsComponent,
    FiscalizationModalComponent,
    InvoiceHistoryModalComponent,
    SearchFilterPipe,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NumberInputComponent,
    NumberInputKolicinaComponent,
    RolesAdministrationFormModalComponent,
    UserAdministrationFormModalComponent,
    TextAreaEditorComponent,
    EditorModule,
    InlineEditorComponent,
    IosModalComponent,
    NgxPaginationComponent,
    NumberInputFinancijeComponent,
    UserDropdownComponent,
    AddNewDmsComponent,
    KomitentiZiroRacuniModalComponent,
    DmsKomentarComponent,
    ClipboardListModalComponent,
    AddNewFinProjektComponent,
    OdaberiFinNalogModalComponent,
    OdaberiTipPocetnogStanjaModalComponent,
    FilterOdDoDatumProjektComponent
  ],
})
export class SharedModule { }

