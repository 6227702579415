<div class="card card-body mb-0">
    <div class="mb-4 h6">{{"DASHBOARD.Uknjiži obračun PDV-a" | translate}} </div>

    <div class="row border-bottom pb-3 mb-3">
        <div class="col-12">

            <div class="col-4 col-xl-4 form-group">
                <label class="font-weight-bold">
                    <span class=""> {{ "PROPERTIES.Mjesec obračuna" | translate }}</span>
                </label>
                <div class="input-group custom-group">
                    <ng-select class="col p-0" [items]="months" loadingText="Ucitavanje..." [virtualScroll]="true"
                        [selectOnTab]="true" [(ngModel)]="params.mjesec" (change)="getList()" [clearable]="false">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div>{{ item }}</div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>

            <div class="pagination-space">
                <table class="table w-100 table-bordered">
                    <thead>
                        <tr>
                            <td>
                                <div class="h6 p-2 mb-0 d-flex align-items-center">
                                    {{ "PROPERTIES.Vrsta" | translate }}
                                </div>
                            </td>
                            <td>
                                <div class="h6 p-2 mb-0 d-flex align-items-center">
                                    {{ "PROPERTIES.Konto" | translate }}
                                </div>
                            </td>
                            <td>
                                <div class="h6 p-2 mb-0 d-flex align-items-center">
                                    {{ "PROPERTIES.Opis" | translate }}
                                </div>
                            </td>
                            <td>
                                <div class="h6 p-2 mb-0 d-flex align-items-center">
                                    {{ "PROPERTIES.Duguje" | translate }}
                                </div>
                            </td>
                            <td>
                                <div class="h6 p-2 mb-0 d-flex align-items-center">
                                    {{ "PROPERTIES.Potražuje" | translate }}
                                </div>
                            </td>
                        </tr>
                    </thead>

                    <tbody class="tbody">
                        @for (item of list; track $index) {
                        <tr>
                            <td>
                                <p class="itemtext">{{ item?.opis }}</p>
                            </td>
                            <td>
                                <p class="itemtext">{{ item?.kontoPdv }}</p>
                            </td>
                            <td>
                                <p class="itemtext">{{ item?.nKontoPdv }}</p>
                            </td>
                            @if (item.tip == 1) {
                            <td>
                                <p class="itemtext text-right">{{ item?.iznosPDVa | number : '1.2-2' }}</p>
                            </td>
                            <td>
                                <p class="itemtext text-right"> 0.00</p>
                            </td>
                            }
                            @else {
                            <td>
                                <p class="itemtext text-right"> 0.00</p>
                            </td>
                            <td>
                                <p class="itemtext text-right">{{ item?.iznosPDVa | number : '1.2-2'}}</p>
                            </td>
                            }
                        </tr>
                        }

                        <tr class="bg-secondary text-light">
                            <td class="text-right text-light" colspan="3">{{'PROPERTIES.SALDO' |
                                translate}}
                            </td>
                            <td class="text-light text-right"> {{ getSumDuguje() | number : '1.2-2'}}</td>
                            <td class="text-light text-right">{{ getSumPotrazuje() | number : '1.2-2'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="loading" class="loader-box">
                <div class="loader-2"></div>
            </div>

            <div class="row col-12 mt-3 mb-0" style="height: 100px;">
                <!--  <button class="btn btn-light mr-2" (click)="closeModal()" type="button">
                    {{"PROPERTIES.Zatvori" | translate}}
                </button> -->

                <div class="col-6">
                    <label class="font-weight-bold">
                        <span class=""> {{"PROPERTIES.Nalog" | translate}}</span>
                    </label>
                    <div class="input-group custom-group">
                        <ng-select class="col p-0" [items]="finNalozi" loadingText="Ucitavanje..." bindLabel="opis"
                            bindValue="nalog" [virtualScroll]="true" [selectOnTab]="true" [(ngModel)]="params.nalog"
                            (change)="finNalogChange()">
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <div> {{ item.opis }}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>

                <div class="col-3">
                    <button class="btn btn-primary" style="margin-top: 30px;" type="submit" (click)="uknjizi()">
                        {{"PROPERTIES.Uknjiži" | translate}}
                    </button>
                </div>
            </div>

        </div>
    </div>