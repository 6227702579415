import { Component, ViewEncapsulation, HostListener, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { FiscalizationModalComponent } from '../modals/Fiscalization/fiscalization-modal/fiscalization-modal.component';
import { IosModalComponent } from '../modals/ios-modal/ios-modal.component';
import { FilterModalOdDoComponent } from '../modals/filter-modal-od-do/filter-modal-od-do.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PdvReportingService } from '../../services/pdv-reporting.service';
import { ModalMjesecComponent } from '../modals/modal-mjesec/modal-mjesec.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { WaitingModalComponent } from '../modals/waiting-modal/waiting-modal.component';
import { PdvService } from '../../services/pdv.service';
import { ModalEIsporukeNabavkeModalComponent } from '../modals/modal-e-isporuke-nabavke-modal/modal-e-isporuke-nabavke-modal.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FinNaloziPodaciService } from '../../services/fin-nalozi-podaci.service';
import { ConfirmationModalThreeOptsComponent } from '../modals/confirmation-modal-three-opts/confirmation-modal-three-opts.component';
import { StalnaSredstvaService } from '../../services/stalna-sredstva.service';
import { FileHelper } from '../../helpers/open-pfd.helper';
import { UgovorODjeluVirmaniDoprinosiModalComponent } from 'src/app/pages/menus/hr/ugovor-o-djelu/ugovor-o-djelu-virmani-doprinosi-modal/ugovor-o-djelu-virmani-doprinosi-modal.component';
import { MenadzerskiUgovorVirmaniDoprinosiModalComponent } from 'src/app/pages/menus/hr/menadzerski-ugovor/menadzerski-ugovor-virmani-doprinosi-modal/menadzerski-ugovor-virmani-doprinosi-modal.component';
import { PdvKnjizenjeObracunaPdvaModalComponent } from '../modals/pdv-knjizenje-obracuna-pdva-modal/pdv-knjizenje-obracuna-pdva-modal.component';
import { YesnoModalComponent } from '../modals/yesno-modal/yesno-modal.component';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
  public isMobile: boolean = window.innerWidth < 991 && window.outerWidth < 991;

  bsModalRef: BsModalRef;
  public yearsToDisplay = [];

  constructor(
    private router: Router,
    public navServices: NavService,
    public layout: LayoutService,
    private datePipe: DatePipe,
    private pdvReportingService: PdvReportingService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private pdvService: PdvService,
    private modalService: BsModalService,
    private finNaloziPodaciService: FinNaloziPodaciService,
    private ssService: StalnaSredstvaService,
  ) {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });

    var contextFirmData = JSON.parse(localStorage.getItem('contextFirmData'));
    this.yearsToDisplay = contextFirmData?.godine?.map(x => x.godina);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }

  openFiscalizationModal() {
    this.bsModalRef = this.modalService.show(FiscalizationModalComponent, { class: "modal-dialog-centered modal-lg" });
  }

  openSaldaKarticaSS() {
    var payload = {
      odPJ: 1,
      doPJ: 999,
      odArtikla: 1,
      doArtikla: 99999999,
    }
    this.ssService.getSaldaKartica(payload).subscribe((res) => {
      FileHelper.downloadFile(res);
    });
  }

  openIOSModal() {
    this.bsModalRef = this.modalService.show(IosModalComponent, { class: "modal-dialog-centered modal-lg" });
  }

  openAutoKnjizenjeIzRIM() {
    const initialState: ModalOptions = {
      initialState: {
        text: this.translate.instant("MESSAGE.Želite li izvršiti automatska knjiženja iz RIM-a") + "?"
      },
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(ConfirmationModalThreeOptsComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        if (res == 1) return; //odgovor "NE"

        var payload = {
          knjiziUGlavnuKnjigu: (res == 2) ? true : false
        }
        this.finNaloziPodaciService.autoKnjizenjeIzRIM(payload).subscribe(resp => {
          this.toastr.info(this.translate.instant("MESSAGE.Proces knjiženja dokumenata iz RIM-a završen"))
        });

        this.bsModalRef.hide();
        this.showBrojNeuknjizenihDokIzRIMa();
      }
    });
  }

  reportFormMjesecGodina = new UntypedFormGroup({
    mjesec: new UntypedFormControl(this.getCurrentMonth(), [Validators.required, this.isBeetweenLength]),
    godina: new UntypedFormControl(new Date().getFullYear(), [Validators.required]),
  });

  /*  openPlatneListe() {
     const initialState: ModalOptions = {
       initialState: {
         reportForm: this.reportFormMjesecGodina,
         yearsToDisplay: this.yearsToDisplay
       },
       class: 'modal-dialog-centered'
     };
     this.bsModalRef = this.modalService.show(ModalMjesecGodinaComponent, initialState);
     this.bsModalRef.content.updateInputValue.subscribe((value: any) => {
       if (value) {
         this.obracunPlacaReportService.getPlatneListe(value.godina, value.mjesec).subscribe(resp => {
           const blob = new Blob([resp.body], { type: resp.body.type, });
           const url = window.URL.createObjectURL(blob);
           window.open(url);
         })
       }
     });
   } */

  showBrojNeuknjizenihDokIzRIMa() {
    var params = {
      page: 1,
      pageSize: 30,
      pageCount: 1,
    };

    this.finNaloziPodaciService.getNeuknjizeniRimDokumenti(params).subscribe(resp => {
      var vrijeme = this.procjenjenoVrijemeUknjizavanja(resp.rowCount);
      this.swallPredvidjenoVrijeme(vrijeme);
    });
  }

  swallPredvidjenoVrijeme(vrijeme) {
    Swal.fire({
      title: this.translate.instant("MESSAGE.Proces knjiženja pokrenut"),
      html:
        //`<div> ${this.translate.instant("MESSAGE.Proces knjiženja pokrenut")} </div><br>` +
        //  `<div> ${this.translate.instant("MESSAGE.Nakon završetka knjiženja dobit ćete notifikaciju")} </div><br>` +
        `<div> ${this.translate.instant("PROPERTIES.Predviđeno vrijeme čekanja uknjižavanja dokumenta je")} : <strong> ${vrijeme} </strong></p> `,
      //type: 'success',

      // text: this.translate.instant("PROPERTIES.Predviđeno vrijeme čekanja uknjižavanja dokumenta je \n") + `: ${ vrijeme } `,
      showConfirmButton: true,
    });
  }

  reportFormKif = new UntypedFormGroup({
    od: new UntypedFormControl(this.firstDaySalesReport(), Validators.required),
    do: new UntypedFormControl(this.lastDaySalesReport(), Validators.required),
  });

  reportFormMjesec = new UntypedFormGroup({
    mjesec: new UntypedFormControl(this.getCurrentMonth(), [Validators.required, this.isBeetweenLength]),
  });

  reportFormIsporukeNabavke = new UntypedFormGroup({
    godina: new UntypedFormControl(new Date().getFullYear(), [Validators.required]),
    mjesec: new UntypedFormControl(this.getCurrentMonth(), [Validators.required, this.isBeetweenLength]),
    redniBroj: new UntypedFormControl(this.getCurrentMonth(), [Validators.required, this.isBeetweenLength]),
  });

  getCurrentMonth() {
    let date = new Date();
    return date.getMonth() + 1;
  }

  firstDaySalesReport() {
    let date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    date.setDate(date.getDate() - 7);
    let pipedDate = this.datePipe.transform(firstDay, 'yyyy-MM-ddT00:00');
    return pipedDate;
  }

  lastDaySalesReport() {
    let date = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let pipedDate = this.datePipe.transform(lastDay, 'yyyy-MM-ddT23:59');
    return pipedDate;
  }

  openKifPoDatumuModal() {
    const initialState: ModalOptions = {
      initialState: {
        reportForm: this.reportFormKif,
      },
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(FilterModalOdDoComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((value: any) => {
      if (value) {
        var payload = {
          od: value.od,
          do: value.do
        }
        this.pdvReportingService.getKifKompletanPdf(payload).subscribe((res) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }, error => {
        });
      }
    });
  }

  openKufPoDatumuModal() {
    const initialState: ModalOptions = {
      initialState: {
        reportForm: this.reportFormKif,
      },
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(FilterModalOdDoComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((value: any) => {
      if (value) {
        var payload = {
          od: value.od,
          do: value.do
        }
        this.pdvReportingService.getKufKompletanPdf(payload).subscribe((resp) => {
          const blob = new Blob([resp.body], { type: resp.body.type, });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }, error => {
        });
      }
    });
  }

  openPdvPrijavaModal() {
    const initialState: ModalOptions = {
      initialState: {
        reportForm: this.reportFormMjesec,
      },
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(ModalMjesecComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((value: any) => {
      if (value) {
        var payload = {
          mjesec: value.mjesec
        }
        this.pdvReportingService.getPdvPrijavaPdf(payload).subscribe((res) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }, error => {
          this.toastr.error(
            this.translate.instant("MESSAGE.Nisu pronađeni ulazi-izlazi u PDV modulu"));
        });
      }
    });
  }

  private isBeetweenLength(control) {
    return !(control.value < 1 || control.value > 12)
      ? null
      : {
        isBeetweenLength: {
          valid: false,
        },
      };
  }

  openModalAutoKnjizenjeRimPDV() {
    const initialState: ModalOptions = {
      initialState: {
        reportForm: this.reportFormMjesec,
      },
      class: 'modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(WaitingModalComponent, initialState);

    this.pdvService.knjiziIzRIM(null).subscribe(resp => {
      this.bsModalRef.hide();
      this.toastr.info(this.translate.instant("MESSAGE.Proces završen!"))
    }, error => {
      this.bsModalRef.hide();
    })

  }

  exportEIsporuke() {
    const initialState: ModalOptions = {
      initialState: {
        reportForm: this.reportFormMjesec,
      },
      class: 'modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(ModalEIsporukeNabavkeModalComponent, initialState);
    this.bsModalRef.content.reportForm = this.reportFormIsporukeNabavke;

    this.bsModalRef.content.updateInputValue.subscribe((value: any) => {
      if (value) {
        var payload = {
          godina: value.godina,
          mjesec: value.mjesec,
          redniBroj: value.mjesec
        }
        this.pdvService.getEIsporuke(payload).subscribe((resp) => {
          const blob = new Blob([resp.body], {
            type: 'text/csv',
          });
          const url = window.URL.createObjectURL(blob);

          let fileName = 'file';
          const contentDisposition = resp.headers.get('Content-Disposition');
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }

          var anchor = document.createElement('a');
          anchor.download = fileName;
          anchor.href = url;
          anchor.click();
        }, error => {
          this.toastr.error(
            this.translate.instant("MESSAGE.Nisu pronađeni ulazi-izlazi u PDV modulu"));
        });
      }
    });
  }

  exportENabavke() {
    const initialState: ModalOptions = {
      initialState: {
        reportForm: this.reportFormMjesec,
      },
      class: 'modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(ModalEIsporukeNabavkeModalComponent, initialState);
    this.bsModalRef.content.reportForm = this.reportFormIsporukeNabavke;

    this.bsModalRef.content.updateInputValue.subscribe((value: any) => {
      if (value) {
        var payload = {
          godina: value.godina,
          mjesec: value.mjesec,
          redniBroj: value.mjesec
        }
        this.pdvService.getENabavke(payload).subscribe((resp) => {
          const blob = new Blob([resp.body], {
            type: 'text/csv',
          });
          const url = window.URL.createObjectURL(blob);

          let fileName = 'file';
          const contentDisposition = resp.headers.get('Content-Disposition');
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }

          var anchor = document.createElement('a');
          anchor.download = fileName;
          anchor.href = url;
          anchor.click();
        }, error => {
          this.toastr.error(
            this.translate.instant("MESSAGE.Nisu pronađeni ulazi-izlazi u PDV modulu"));
        });
      }
    });
  }

  procjenjenoVrijemeUknjizavanja(broj) {
    var estimatedPerMinute = 300;
    var estimatedTime = broj / estimatedPerMinute;

    if (estimatedTime < 2) return "manje od 1 min"
    else if (estimatedTime < 60) return Math.floor(estimatedTime) + "min"
    else return Math.floor((estimatedTime / 60)) + "min"

  }

  inRange(x, min, max) {
    return ((x - min) * (x - max) <= 0);
  }

  isSidebarAnchor(path) {
    if (path == '/articles' || path == '/invoice/11' || path == '/invoice/calculation-list/2'
      || path == 'pdv/kuf-podaci' || path == '/financije/pripremni-nalozi') {
      return true;
    }
    return false;
  }

  getAnchorHeader(path) {
    if (path == '/articles') return 'start-artikli'
    else if (path == '/invoice/11') return 'start-faktura'
    else if (path == '/invoice/calculation-list/2') return 'start-kalkulacija';
    else if (path == 'pdv/kuf-podaci') return 'start-kuf';
    else if (path == '/financije/pripremni-nalozi') return 'start-unos-naloga';
  }

  ugovoODjeluVirmaniDoprinosi() {
    const initialState: ModalOptions = {
      initialState: {
      },
      class: 'modal-dialog-centered modal-xl'
    };
    this.bsModalRef = this.modalService.show(UgovorODjeluVirmaniDoprinosiModalComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
    });
  }

  menadzerskiUgovorVirmaniDoprinosi() {
    const initialState: ModalOptions = {
      initialState: {
      },
      class: 'modal-dialog-centered modal-xl'
    };
    this.bsModalRef = this.modalService.show(MenadzerskiUgovorVirmaniDoprinosiModalComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
    });
  }

  obracunPDVKnjiziUFinancije() {
    const initialState: ModalOptions = {
      initialState: {},
      class: 'modal-dialog-centered modal-xl'
    };
    this.bsModalRef = this.modalService.show(PdvKnjizenjeObracunaPdvaModalComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((resp: any) => {
      if (resp) {
        var initialState = {
          initialState: {
            text: this.translate.instant("MESSAGE.Proces završen") + '. ' + this.translate.instant("MESSAGE.Želite li pregledati i uknjižiti nalog u glavnu knjigu") + "?"
          },
          class: 'modal-dialog-centered'
        };
        this.bsModalRef = this.modalService.show(YesnoModalComponent, initialState);
        this.bsModalRef.content.updateInputValue.subscribe(res => {
          if (res) {
            this.router.navigateByUrl('/financije/pripremni-nalozi/' + resp.nalog);
          }
        });
      }
    });
  }
}
