import { BaseModel } from "../base.model";

export class VirmaniReportDto extends BaseModel {
    virman: number;
    iznos: number;
    opcina: number;
    nOpcina: string;
    kanton: number;
    nKanton: string;
    grupisati: number;
    nazivVirmana: string;
    svrhaDoznake: string;
    primalac: string;
    racccunPosiljaoca: string;
    racccunPrimaoca: string;
    brojPorObveznika: string;
    vrstaPrihoda: string;
    oznakaOpccine: string;
    pozivNaBroj: string;
    budzzetskaOrganizacija: string;
    stopa: number;
    brutoPlaca: number;
}