<div class="card card-body mb-0">
    <div class="mb-4 h6">Virmani - ugovor o djelu</div>
    <div class="row border-bottom pb-3 mb-3">
        <div class="col-12">

            <div class="col-12 col-xl-6 form-group">
                <label class="font-weight-bold">
                    <span class=""> {{ "PROPERTIES.Obračun" | translate }}</span>
                </label>
                <div class="input-group custom-group">
                    <ng-select class="col p-0" [items]="obracuni" loadingText="Ucitavanje..." [virtualScroll]="true"
                        [selectOnTab]="true" [(ngModel)]="params.autoBr" bindValue="autoBr" bindLabel="nazivObracccuna"
                        (change)="getStavke()" [clearable]="false">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div>{{ item.nazivObracccuna }}</div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>

            <div class="pagination-space">
                <div>
                    <div class="table-responsive invoice-table" id="table" style="height: 500px;">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Naziv virmana" | translate}} - {{rowCount}}
                                        </div>
                                    </td>

                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Iznos za uplatu" | translate}}
                                        </div>
                                    </td>

                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Račun primatelja" | translate}}
                                        </div>
                                    </td>

                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Broj poreskog obveznika" | translate}}
                                        </div>
                                    </td>

                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Vrsta prihoda" | translate}}
                                        </div>
                                    </td>

                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Općina" | translate}}
                                        </div>
                                    </td>

                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Budžetska organizacija" | translate}}
                                        </div>
                                    </td>

                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Poziv na broj" | translate}}
                                        </div>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let item of stavke; index as i">
                                    <td role="button">
                                        <p class="itemtext">{{ item?.nazivVirmana }}</p>
                                    </td>
                                    <td role="button">
                                        <p class="itemtext">{{ item?.iznos | number : '1.2-2' }}</p>
                                    </td>
                                    <!--  <td role="button">
                                        <p class="itemtext">{{ item?.primalac }}</p>
                                    </td> -->
                                    <td role="button">
                                        <p class="itemtext">{{ item?.racccunPrimaoca }}</p>
                                    </td>
                                    <td role="button">
                                        <p class="itemtext">{{ item?.brojPorObveznika }}</p>
                                    </td>
                                    <td role="button">
                                        <p class="itemtext">{{ item?.vrstaPrihoda }}</p>
                                    </td>
                                    <td role="button">
                                        <p class="itemtext">{{ item?.oznakaOpccine }}</p>
                                    </td>
                                    <td role="button">
                                        <p class="itemtext">{{ item?.budzzetskaOrganizacija }}</p>
                                    </td>
                                    <td role="button">
                                        <p class="itemtext">{{ item?.pozivNaBroj }}</p>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="loading" class="loader-box">
                <div class="loader-2"></div>
            </div>

            <div class="d-flex align-items-center justify-content-end mt-4">
                <!-- <button class="btn btn-light mr-2" (click)="closeModal()" type="button">
                    {{ "PROPERTIES.Zatvori" | translate }}
                </button> -->

                <ng-select #artikalElement class="col-5 p-0 select-append" (keyup.enter)="$event.stopPropagation()"
                    [items]="downloadTypes" bindLabel="label" bindValue="value" formControlName="artikal"
                    loadingText="Ucitavanje..." (change)="setDownloadType($event)" [virtualScroll]="true"
                    [selectOnTab]="true">
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div class="w-100 d-flex">
                            <span class="w-15 text-wrap"> <i class="fa fa-download mr-2"></i></span>
                            <span class="w-85 text-wrap">{{ item.label }}</span>
                        </div>
                    </ng-template>
                </ng-select>

                <button class="btn btn-primary ml-1" type="button" (click)="getNalogZaPlacanje()"
                    [ngbTooltip]="'PROPERTIES.Preuzmi nalog za plaćanje' | translate">
                    <i class="fa fa-download"></i> </button>
            </div>
        </div>
    </div>
</div>