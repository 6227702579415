import { Component, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FileHelper } from 'src/app/shared/helpers/open-pfd.helper';
import { MenadzerskiUgovorReportService } from 'src/app/shared/services/menadzerski-ugovor-report.service';
import { MenadzerskiUgovorService } from 'src/app/shared/services/menadzerski-ugovor.service';

@Component({
  selector: 'app-menadzerski-ugovor-virmani-doprinosi-modal',
  templateUrl: './menadzerski-ugovor-virmani-doprinosi-modal.component.html',
  styleUrl: './menadzerski-ugovor-virmani-doprinosi-modal.component.scss'
})
export class MenadzerskiUgovorVirmaniDoprinosiModalComponent {
  @Input() updateInputValue = new EventEmitter();
  obracuni;
  stavke;
  rowCount;
  loading;
  downloadTypes = [{ label: 'Doprinosi Sparkasse', value: 0, nalogZaPlacanje: 1 }, { label: 'Doprinosi Raiffeisen', value: 1, nalogZaPlacanje: 2 },
  { label: 'Plaće Sparkasse', value: 2, nalogZaPlacanje: 1 }, { label: 'Plaće Raiffeisen', value: 3, nalogZaPlacanje: 2 }]
  downloadType;

  params = {
    autoBr: 0
  }

  constructor(
    private muReportService: MenadzerskiUgovorReportService,
    private muService: MenadzerskiUgovorService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.getAllObracuni();
  }

  getAllObracuni() {
    this.muService.getAll().subscribe(resp => {
      this.obracuni = resp;
    })
  }

  getStavke() {
    this.loading = true;
    this.muReportService.virmaniDoprinosi(this.params).subscribe(resp => {
      this.loading = false;
      this.stavke = resp;
      this.rowCount = this.stavke.length;
    })
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  setDownloadType(event) {
    this.downloadType = event;
  }

  getNalogZaPlacanje() {
    if (this.params.autoBr == 0) {
      this.toastr.error(this.translate.instant("MESSAGE.Molim odaberite obračun"))
      return;
    }

    if (this.downloadType.value == 0 || this.downloadType.value == 1) {
      var payload = {
        autoBr: this.params.autoBr,
        nalogZaPlacanje: this.downloadType.nalogZaPlacanje
      }
      this.muReportService.GetNalogZaPlacanjeDoprinosa(payload).subscribe(resp => {
        FileHelper.downloadFile(resp);
      })
    }
    else if (this.downloadType.value == 2 || this.downloadType.value == 3) {
      var payload = {
        autoBr: this.params.autoBr,
        nalogZaPlacanje: this.downloadType.nalogZaPlacanje
      }
      this.muReportService.GetNalogZaPlacanjePlaca(payload).subscribe(resp => {
        FileHelper.downloadFile(resp);
      })
    }
  }
}