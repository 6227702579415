import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { MenadzerskiUgovorDto } from '../models/hr/menadzerski-ugovor.model';

@Injectable({
  providedIn: 'root'
})
export class MenadzerskiUgovorService {
  constructor(
    private api: BaseApiService,
    private accountService: AccountService,
  ) { }

  getAll(): Observable<MenadzerskiUgovorDto[]> {
    return this.api.get('menadzerski-ugovor/all').pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new MenadzerskiUgovorDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<MenadzerskiUgovorDto[]>(`getvirmaniAll`))
    );
  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
