import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-yesno-modal',
  templateUrl: './yesno-modal.component.html',
  styleUrls: ['./yesno-modal.component.scss']
})
export class YesnoModalComponent implements OnInit {
  @Input() updateInputValue = new EventEmitter();
  text: string;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  closeModal(value) {
    this.updateInputValue.emit(value);
    this.bsModalRef.hide();
  }
}
