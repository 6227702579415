import { KomitentiLookupDto } from "src/app/models/sifrarnici/KomitentiLookupDto";
import { BaseModel } from "./base.model";
import { FinNaloziDto } from "./fin-nalozi.model";
import { FinDokumentiDto } from "./fin-dokumenti.model";
import { FiweDmsMultipleDto } from "./dms/fiweDmsMultiple.model";

export class FinNaloziPodaciDto extends BaseModel {
    autoBr: number;
    nalog: number;
    knjizzenje: number | null;
    dokument: number;
    broj: string;
    opis: string;
    datum: string;
    rokPlaccanja: number | null;
    datumPlaccanja: string | null;
    mjestoPrihodaTrosska: number | null;
    konto: string;
    komitent: number | null;
    valutaDuguje: number | null;
    valutaPotrazzuje: number | null;
    duguje: number;
    potrazzuje: number;
    knjizzeno: boolean | null;
    robno: number | null;
    blagajna: number | null;
    status: number | null;
    racccunBroj: string;
    brojBlagajne: number | null;
    brojFakture: number | null;
    projekt: number | null;
    rbrPdv: string;
    nKomitent: string;
    nKonto: string;
    nDokument: string;
    komitenti: KomitentiLookupDto;
    nalozi: FinNaloziDto;
    dokumenti: FinDokumentiDto;
    fiweDmsMultiple: FiweDmsMultipleDto[];

    get dateFormatted() {
        return new Date(this.datum).toLocaleDateString();
    }
}