import { Component, EventEmitter, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FinNaloziService } from 'src/app/shared/services/fin-nalozi.service';
import { PdvReportingService } from 'src/app/shared/services/pdv-reporting.service';

@Component({
  selector: 'app-pdv-knjizenje-obracuna-pdva-modal',
  templateUrl: './pdv-knjizenje-obracuna-pdva-modal.component.html',
  styleUrl: './pdv-knjizenje-obracuna-pdva-modal.component.scss'
})
export class PdvKnjizenjeObracunaPdvaModalComponent {
  @Input() updateInputValue = new EventEmitter();
  loading = false;
  saving: boolean = false;
  list;
  finNalozi;
  filteredFinNalozi;

  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  params = {
    mjesec: new Date().getMonth() + 1,
    nalog: 0,
    dokument: 0,
  }

  constructor(
    public bsModalRef: BsModalRef,
    private pdvReportingService: PdvReportingService,
    private finNaloziService: FinNaloziService,
    private toatr: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getList();
    this.getNaloge();
  }

  getList() {
    this.loading = true;
    this.list = [];
    this.pdvReportingService.getObracunPDVaList(this.params).subscribe(resp => {
      this.list = resp.body.result.item;
      this.loading = false;
    })
  }

  getNaloge() {
    this.finNaloziService.getAll().subscribe(resp => {
      this.finNalozi = resp;
      this.filteredFinNalozi = resp;

      var filter = this.finNalozi.filter(x => x.opis.toUpperCase().includes('PDV'));
      if (filter) {
        this.params.nalog = filter[0].nalog;
        this.params.dokument = filter[0].dokument;
      }
    })
  }

  forceCloseModal() {
    this.updateInputValue.emit(false);
    this.bsModalRef.hide();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  getSumDuguje() {
    var sum = 0;
    this.list.forEach(element => {
      if (element.tip == 1) sum += element.iznosPDVa;
    });
    return sum;
  }

  getSumPotrazuje() {
    var sum = 0;
    this.list.forEach(element => {
      if (element.tip == 2) sum += element.iznosPDVa;
    });
    return sum;
  }

  finNalogChange() {
    var selectedNalog = this.finNalozi.find((type) => type.nalog == this.params.nalog);
    this.params.dokument = selectedNalog.dokument;
  }

  uknjizi() {
    this.pdvReportingService.obracunPDVKnjiziUFinancije(this.params).subscribe(resp => {
      this.toatr.success(this.translate.instant("MESSAGE.Proces završen"));
      this.updateInputValue.emit(this.params);
      this.closeModal();
    })
  }
}
