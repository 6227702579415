import { BaseModel } from "../base.model";

export class MenadzerskiUgovorDto extends BaseModel {
    autoBr: number;
    nazivObracccuna: string | null;
    porezniPeriod: number | null;
    periodOd: string | null;
    periodDo: string | null;
    koeficijent: number | null;
    datumObracccuna: string | null;
    datumIsplate: string | null;
    menadzerskiUgovorPodaci: MenadzerskiUgovorPodaciDto;
}

export class MenadzerskiUgovorPodaciDto extends BaseModel {
    autoBr_: number;
    autoBr: number;
    radnik: number | null;
    netoBezPoreza: number | null;
    prihod: number | null;
    rashod: number | null;
    dohodak: number | null;
    doprinosZaZdravstvenoOsiguranje: number | null;
    osnovicaZaPorez: number | null;
    porezNaDohodak: number | null;
    doprinosZaPio: number | null;
    doprinosSolidarnosti: number | null;
    zaIsplatu: number | null;
    koeficijent: number | null;
    osnovicaZaObracccunPorezaDoprinosa: number | null;
    priznatiRashodi: number | null;
    osnovicaZaZdravstvenoOsiguranje: number | null;
    naknadaZaZasstituOdPrirodnihIDrugihNesreca: number | null;
    opsstaVodnaNaknada: number | null;
    ukupnoTroskoviPoOsnovuUgovora: number | null;
    ukupnaStopaPorezaDoprinosaIPosebnihNaknada: number | null;
}