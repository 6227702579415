import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/shared/services/client.service';
import { FinancijeIosService } from 'src/app/shared/services/financije-ios.service';

@Component({
  selector: 'app-ios-modal',
  templateUrl: './ios-modal.component.html',
  styleUrls: ['./ios-modal.component.scss']
})
export class IosModalComponent implements OnInit {
  komitenti;
  loading = {};

  lastDay() {
    let date = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let pipedDate = this.datePipe.transform(lastDay, 'yyyy-MM-ddT23:59');
    return pipedDate;
  }

  form = new UntypedFormGroup({
    komitent: new UntypedFormControl(1, Validators.required),
    naDan: new UntypedFormControl(this.lastDay(), Validators.required),
    konto: new UntypedFormControl('2110', Validators.required),
  });

  constructor(private clientService: ClientService,
    private iosService: FinancijeIosService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private translate: TranslateService,
    private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.getKomitente();
  }


  onSubmit() {
    this.loading['getIos'] = true
    this.iosService.getPdf(this.form.value).subscribe((res) => {
      this.loading['getIos'] = false
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      //FileHelper.openPdf(res);
    }, error => {
      this.toastr.error(this.translate.instant("MESSAGE.IOS prazan za zadanog komitenta"));
      this.loading['getIos'] = false
    });
  }


  getKomitente() {
    this.clientService.getAllClients().subscribe(resp => {
      this.komitenti = resp;
    })
  }

  closeModal() {
    this.bsModalRef.hide();
  }

}
