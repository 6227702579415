import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApiService } from './base-api.service';
import { FinNaloziDto } from '../models/fin-nalozi.model';
import { HttpResponse } from '@angular/common/http';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { PagedResponse } from '../models/paged-response.model';

@Injectable({
  providedIn: 'root'
})
export class FinNaloziService {
  constructor(private api: BaseApiService) { }

  getAll(params: any = {}): Observable<FinNaloziDto[]> {
    return this.api.post('financije/unos-naloga/all', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new FinNaloziDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<FinNaloziDto[]>(`getAllFinNalozi`))
    );
  }

  getPaged(params: any): Observable<PagedResponse<FinNaloziDto>> {
    return this.api.post('financije/unos-naloga', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<FinNaloziDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new FinNaloziDto(i));
        return pagedModel;
      })
    );
  }

  getById(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga/by-id', params)
      .pipe(catchError(this.handleError<[]>(`financije/unos-naloga/by-id`)));
  }

  update(params: any = {}): Observable<any> {
    return this.api
      .post('financije/unos-naloga/update', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga/update')));
  }

  create(params: any = {}): Observable<any> {
    return this.api
      .post('financije/unos-naloga/create', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga/create')));
  }

  delete(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga/delete', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga/delete')));
  }

  getCreatePropertyList() {
    return this.api
      .get('financije/unos-naloga/create-property-list')
      .pipe(catchError(this.handleError<[]>(`financije/unos-naloga/create-property-list`)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
