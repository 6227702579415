<div class="card card-body mb-0">
  <div class="mb-4 h6">{{text}}</div>
  <div class="d-flex justify-content-end">
    <button class="btn btn-light mr-3" (click)="closeModal(false)">
      {{"NE" | translate}}
    </button>
    <button class="btn btn-primary" (click)="closeModal(true)">
      {{"DA" | translate}}
    </button>
  </div>
</div>