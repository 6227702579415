import { BaseModel } from "../base.model";

export class UgovorODjeluDto extends BaseModel {
    autoBr: number;
    nazivObracccuna: string | null;
    porezniPeriod: number | null;
    periodOd: string | null;
    periodDo: string | null;
    koeficijent: number | null;
    datumObracccuna: string | null;
    datumIsplate: string | null;
    status: number | null;
    created: string | null;
    createdBy: number | null;
    modified: string | null;
    modifiedBy: number | null;
    ugovorODjeluPodaci: OpUgovorODjeluPodaciDto[];
    ugovorODjeluVirmaniObracun: OpUgovorODjeluVirmaniObracunDto[];
}

export class OpUgovorODjeluPodaciDto extends BaseModel {
    autoBr_: number;
    autoBr: number;
    radnik: number | null;
    ugovorenaNetoNaknada: number | null;
    koeficijent: number | null;
    osnovicaZaObracccunPorezaDoprinosa: number | null;
    priznatiRashodi: number | null;
    osnovicaZaZdravstvenoOsiguranje: number | null;
    doprinosZaZdravstvenoOsiguranje: number | null;
    osnovicaZaPorez: number | null;
    porezNaDohodak: number | null;
    doprinosZaPio: number | null;
    zaIsplatu: number | null;
    naknadaZaZasstituOdPrirodnihIDrugihNesreca: number | null;
    opsstaVodnaNaknada: number | null;
    ukupnoTroskoviPoOsnovuUgovora: number | null;
    ukupnaStopaPorezaDoprinosaIPosebnihNaknada: number | null;
}

export class OpUgovorODjeluVirmaniObracunDto extends BaseModel {
    autoBr_: number;
    autoBr: number;
    redniBroj: number | null;
    adminJedinica: number | null;
    radnik: number;
    kanton: number | null;
    opccina: number | null;
    nazivVirmana: string | null;
    svrhaDoznake: string | null;
    primalac: string | null;
    racccunPosiljaoca: string | null;
    racccunPrimaoca: string | null;
    brojPorObveznika: string | null;
    vrstaPrihoda: string | null;
    oznakaOpccine: string | null;
    pozivNaBroj: string;
    budzzetskaOrganizacija: string | null;
    iznos: number | null;
}