import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';
import { VirmaniReportDto } from '../models/hr/virmani-report.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenadzerskiUgovorReportService {
  constructor(
    private api: BaseApiService,
    private accountService: AccountService,
  ) { }

  virmaniDoprinosi(params: any): Observable<VirmaniReportDto[]> {
    return this.api.post('menadzerski-ugovor-report/virmani-doprinosi', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new VirmaniReportDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<VirmaniReportDto[]>(`getobracun-placaAll`))
    );
  }

  GetNalogZaPlacanjeDoprinosa(params) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}menadzerski-ugovor-report/nalog-za-placanje-doprinosa`,
      {
        autoBr: params.autoBr,
        nalogZaPlacanje: params.nalogZaPlacanje
      },
      {},
      {
        headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  GetNalogZaPlacanjePlaca(params) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}menadzerski-ugovor-report/nalog-za-placanje-placa`,
      {
        autoBr: params.autoBr,
        nalogZaPlacanje: params.nalogZaPlacanje
      },
      {},
      {
        headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
