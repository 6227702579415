import { BaseModel } from "./base.model";

export class FinNaloziDto extends BaseModel {
    nalog: number;
    datum: string | null;
    opis: string;
    valuta: number | null;
    kursValute: number | null;
    zatvoren: boolean | null;
    status: number | null;
    dokument: number | null;
    kontoZatvaranja: string;
    konto_blagajne: string;

    nDokument: string;
}